@font-face {
    font-family: 'Assistant';
    src: url('./../../css/Assistant-VariableFont_wght.ttf');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Rubik1';
    font-style: normal;
    font-weight: normal;
    src: url('./../../css/rubik1.woff2') format('woff2');
}
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: normal;
    src: url('./../../css/Rubik-VariableFont_wght.ttf');
}

.MuiDialogContent-root{
    padding: 16px 0 !important;
}


@mixin font-size($size) {
    font-size: calc(($size * 100) / 1080);
}

@function calc-vw($size) {
    $vh-size: calc($size * 100) / 1920;
    @return #{$vh-size}vw;
}

@function calc-vh($size) {
    $vh-size: calc($size * 100) / 1080;
    @return #{$vh-size}vh;
}

$redColor: '#d70007';

*,
html,
body {
    font-family: 'Assistant' !important;
    box-sizing: border-box;
}

body {
    box-sizing: border-box;
    scrollbar-color: transparent transparent;
    scrollbar-width: 5px;
}

*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background: lightgray;
}

*::-webkit-scrollbar-thumb {
    background: gray;
    border: none;
}

* {
    -ms-overflow-style: none;
}
.font_1_5 {
    font-size: 1.5vw !important;
}
.font_1_0 {
    font-size: 1vw !important;
}
/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    box-shadow: 0 0 0 30px white inset !important;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.multilineWidth_70 {
    width: 100% !important;
    border: 1px solid gray !important;
    border-radius:10px;
}
.half_edit_dialog {
    direction: rtl;
    float: right;
    width: 50%;
    height: 4.72vh;
    font-size: 1.48vh;
}
.mar_10 {
    margin: 10px;
}
.cur_drop_pay {
    max-width: 20% !important;
    height: 51px !important;
}
.scarlet {
    color: #d70007;
}

.dialog_grid {
    margin-top: 1.3vh;
}

.regPopup {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background: #00000054 !important;
    top: 0;
    right: 0;

    .reg-form-dialog {
        width: 40vw;
        min-width: 480px;
        background: #fff;
        margin-left: auto;
        padding: calc-vw(60) calc-vh(60);
        position: relative;
        overflow-y: auto;
        min-height: 100vh;
        max-height: 100%;


        .close-dialog-btn {
            position: absolute;
            top: 2.8vh;
            left: 2.8vh;
            min-width: auto;
            width: 2.3vh;
            height: 2.3vh;
        }

        .close-dialog {
            color: red;
            font-size: calc-vh(45);
        }
    }
}

.reg_popup_data {
    direction:ltr;
    .popup_tittle {
        font-size: 36px;
        color: #d70007;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        line-height: 1.11;
        margin-bottom: 3vh;
        font-weight: bold;
        font-family: Assistant !important;
        padding: 0 5vw;

        @media screen and (max-width: 991px) {
            font-size: 24px;
            margin-top: 20px;
        }
    }

    .check-box {
        direction: rtl;
        margin-right: calc-vw(-15);
        display: flex;
        align-items: flex-start;

        span {
            font-size: calc-vw(16.5);
            line-height: 1.4;

            &.MuiCheckbox-colorPrimary {
                padding: 0 0 0 5px;
            }
        }
    }

    .account_type_btn {
        font-weight: bold;
        text-align: left;
        line-height: 2.52;
        color: red;
        display: flex;
        text-align: end;
        align-items: center;
        justify-content: flex-end;
        padding-right: calc-vw(15);
        color: #dc2228;
    }

    .Business {
        display: flex;
        text-align: end;
        align-items: center;
        font-weight: 300;
        line-height: 1.88;
        height: calc-vh(51);
        padding: calc-vw(20) calc-vh(62) calc-vw(19);
        object-fit: contain;
        border: solid 1px #f0f0f2;
        background-color: #f6f6f6;
        color: #1f5e7f;
        cursor: pointer;
    }

    .private {
        display: flex;
        text-align: end;
        align-items: center;
        font-weight: 300;
        line-height: 1.88;
        height: calc-vh(51);
        padding: calc-vw(20) calc-vh(62) calc-vw(19);
        object-fit: contain;
        color: #1f5e7f;
        border: solid 1px #f0f0f2;
        background-color: #f6f6f6;
        cursor: pointer;
    }

    .selected_acc {
        background-color: white;
        font-weight: bold;
    }

    .screen_otp_title_2 {
        font-size: calc-vw(24);
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: center;
        color: #253746;

        @media screen and (max-width: 991px) {
            font-size: 18px;
        }
    }

    .screen_otp_footer_2 {
        font-size: calc-vw(16.5);
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.52;
        letter-spacing: normal;
        text-align: center;
        color: #d70007;
        text-decoration: underline;

        @media screen and (max-width: 991px) {
            font-size: 16px;
        }
    }

    .screen-footer-btn {
        font-size: 16px;
        font-weight: bold;
        line-height: 0.82;
    }

    .screen-footer {
        font-size: calc-vw(16.5);
         line-height: 2.52;
        text-align: center;
        border-top: 1px solid #f0f0f2;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
        color: #d70007;
        font-size: 16px;
    }

    

    @media screen and (max-width: 991px) {
        .screen-footer {
            font-size: 18px;
        }
    }

    .text-field {
        width: 100%;
        direction: rtl;
        min-height: calc-vh(51);
        //font-size: 1.48vh;
        border: 1px solid #ccc;
        label.Mui-error {
            color: #253746;
        }
        // input[type=text] {
        //     background-color: #fff;
        //     line-height: 1.88;
        //     font-size: calc-vw(16);
        // }

        fieldset.MuiOutlinedInput-notchedOutline {
            border: 1px solid #ccc;
        }
    }

    .text-field-otp {
        width: 3vw;
        direction: rtl;
        height: calc-vh(51);
        font-size: 2vw;

        label.Mui-error {
            color: #253746;
        }

        input[type=text] {
            background-color: #fff;
            line-height: 0.67;
            font-weight: bold;
            font-size: 3.125vw;
            margin-right: 0;
            margin-left: 0;
        }

        fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
            border-bottom: 1px solid black;
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.form-action {
    margin-top: 30px;
    text-align:  center;
    .submit-action {
        height: 51px;
        width: 100%;
        font-size: 22px;
        &.small {
            font-size: 16px;
            max-width: 234px;
        }
    }
}

.login-main-container {
    width: 100%;
    height: 680px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-container {
        width: 360px;
        height: 380px;
        border: 1px solid rgb(167, 159, 159);
        background-color: #FFFFFF;
        border-radius: 10px;
        position: absolute;
        box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.14);

        .login-title {
            color: #00a0d1;
            margin-top: 20px;
            text-align: center;

            div {
                display: inline-block;
            }
        }

        .login-title-strong {
            // font-size: 25px;
            // font-weight: 600;
            // letter-spacing: .5px;
            // line-height: 20px;
            // margin-bottom: -7px;
            // color: #00a0d1;
            background-image: url(/resources/logo.png);
            height: 133px;
            width: 311px;
            background-repeat: no-repeat;
            background-position: 109% 2%;
        }

        .login-title-sub {
            // color:#00a0d1;
            color: #6b7375;
            font-weight: 400;
            font-size: 18px;
            letter-spacing: .5px;
            padding: 0px 5px;
        }

        .login_form {
            padding: 20px 35px 10px;

            .login-input {
                text-indent: 10px;
                height: 36px;
                width: 100%;
                min-width: 250px;
                margin-top: 5px;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 8px;

                &:focus {
                    outline: none;
                }
            }

            .login-label {
                opacity: 0.75;
                color: #525E66;
                letter-spacing: 0.4px;

                &.password {
                    margin-top: 22px;
                }
            }

            .login-button {
                margin-top: 35px;
                min-width: 250px;
                width: 100%;
                height: 36px;
                border-radius: 8px;
                background: #1d84c9;
                color: #FFFFFF;
                font-family: Roboto;
                font-weight: 500;
                letter-spacing: 1.25px;
                text-align: center;
                border: 0px solid transparent;
                cursor: pointer;

                .btn-loading {
                    margin-left: 5px;
                    position: relative;
                    top: 5px;
                }

                .btn-label {
                    position: relative;
                    vertical-align: middle;
                    bottom: 1px;
                }
            }
        }

        .login-error {
            color: #f10a0a;
            position: relative;
            text-align: center;
            font-weight: 400;
        }
    }
}

.flex-rtl {
    display: flex;
    direction: rtl;
    @media screen and (max-width: 991px) {
        flex-flow: wrap;
    }
}

.align-items-center {
    align-items: center;
}

.mb-1rem {
    margin-bottom: 1rem;
}
.radio-title {
    @media screen and (max-width: 991px) {
    font-size: 16px;
    color: #d70007;
    font-weight: bold;
    margin: 5px 3px 20px;
    }
}
.prefix-field {
    position: absolute;
    display: flex;
    align-items: center;
    height: calc(100% - 1rem);
    padding: 0 10px;
    justify-content: flex-start;
    z-index: 99;
    max-width: 56px;
    svg {
        cursor: pointer;
        fill: rgb(98, 82, 82);
        font-size: 12px;
    }
}
.position-reletive {
    position: relative;
}
.personalFileReciept {
    .select-field {
        height: 30px !important;
    }

    .MuiInputBase-colorPrimary {
        height: 30px !important;
    }
}

.form-field-wrapper {
    margin-bottom: 1rem;
    width: 100%;

    &.prefix {
        width: calc(100% - 110px);

        input,
        textarea {
            padding: 4px 0;
        }
    }

    &.prefix-select {
        width: 110px;

        &.width-50 {
            width: 50%;
        }

        &.width-30 {
            width: 30%;
        }

        &.no-input-pad {
            input,
            textarea {
                padding: 0 0;
            }
        }
    }

    &.select-field {
        //max-width: 200px;
        width: 100%;
        background: transparent;
        border-radius: 4px;
        display: flex;
        align-items: center;


        &.xs-fullwidth {
            @media screen and (max-width: 767px) {
                max-width: 100%;
            }
        }

        .MuiAutocomplete-hasClearIcon {
            width: 100%;

            .MuiOutlinedInput-root {
                padding: 0;

                .MuiInputBase-input {
                    padding: 6px;
                    background: #fff;
                }

                .MuiAutocomplete-endAdornment {
                    right: auto;
                    left: 8px;
                }

                .MuiOutlinedInput-notchedOutline {
                    border: none;
                }
            }
        }
    }


    .text-field {
        width: 100%;

        input,
        textarea {
            //padding: 5px 0;
            font-size: 18px;
            padding: 0;
            background: #fff;
            color: #253746;
            transform: translate(0, 6px) scale(1);
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        .MuiInputBase-colorPrimary {
            padding: 14px;
            border: 0.4px solid gainsboro;
            border-radius: 4px;

            @media screen and (max-width: 991px) {
                padding: 10px;
            }
        }

        .MuiSelect-select {
            padding: 4px 0;
            font-size: 14px;
            line-height: 19px;
        }

        &.select {
            min-height: 54px;

            @media screen and (max-width: 991px) {
                min-height: 45px;
            }
        }

        .MuiInputLabel-animated {
            transform-origin: top right;
            left: auto;
            right: 0;
            max-width: calc(100%);
            transform: translate(-14px, 12px) scale(1);
            color: #253746;

            &.Mui-focused,
            &.MuiFormLabel-filled {
                transform: translate(-14px, 6px) scale(.65);
                color: #253746;
                font-weight: bold;
            }
        }

        fieldset {
            display: none;
        }
    }

    .text-field-disabled {
        width: 100%;

        input,
        textarea {
            //padding: 5px 0;
            font-size: 18px;
            padding: 0;
            background: #e9e9e9;
            color: #253746;
            transform: translate(0, 6px) scale(1);
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        .MuiInputBase-colorPrimary {
            padding: 14px;
            //border: 1px solid #ccc;
            border-radius: 4px;

            @media screen and (max-width: 991px) {
                padding: 10px;
            }
        }

        .MuiSelect-select {
            padding: 4px 0;
            font-size: 14px;
            line-height: 19px;
        }

        &.select {
            min-height: 54px;

            @media screen and (max-width: 991px) {
                min-height: 45px;
            }
        }

        .MuiInputLabel-animated {
            transform-origin: top right;
            left: auto;
            right: 0;
            max-width: calc(100%);
            transform: translate(-14px, 12px) scale(1);
            color: #253746;

            &.Mui-focused,
            &.MuiFormLabel-filled {
                transform: translate(-14px, 6px) scale(.65);
                color: #253746;
                font-weight: bold;
            }
        }

        fieldset {
            display: none;
        }
    }

    .text-field_big {
        width: 100%;

        input,
        textarea {
            //padding: 5px 0;
            font-size: 18px;
            padding: 0;
            background: #fff;
            color: #253746;
            transform: translate(0, 6px) scale(1);
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        input[type=number] {
            -moz-appearance: textfield;
        }

        .MuiInputBase-colorPrimary {
            padding: 14px;
            //border: 1px solid #ccc;
            border-radius: 4px;

            @media screen and (max-width: 991px) {
                padding: 10px;
            }
        }

        .MuiSelect-select {
            padding: 13px 0;
            font-size: 18px;
            line-height: 19px;
        }

        &.select {
            min-height: 54px;

            @media screen and (max-width: 991px) {
                min-height: 45px;
            }
        }

        .MuiInputLabel-animated {
            transform-origin: top right;
            left: auto;
            right: 0;
            max-width: calc(100%);
            transform: translate(-14px, 12px) scale(1);
            color: #253746;

            &.Mui-focused,
            &.MuiFormLabel-filled {
                transform: translate(-14px, 6px) scale(.65);
                color: #253746;
                font-weight: bold;
            }
        }

        fieldset {
            display: none;
        }
    }

    &.no-animate {
        .MuiInputBase-colorPrimary {
            padding: 0;
            padding-right: 10px;
            border: 0.4px solid gainsboro;
            border-radius: 4px;
        }

        input,
        textarea {
            transform: none;
            padding: 5px 0;
        }
    }
}

    .coupen_action {
        button {
            border-radius: 50%;
            margin: 0 15px 15px;
            min-width: 36px;
            height: 36px;
            padding: 0;
            outline: none;

            &:focus {
                outline: none;
            }
        }
    }
    // Accessiblity Tool styles
    .accessiblity-tool {
        position: fixed;
        top: calc(50vh - 180px);
        transform: translateX(-100%);
        z-index: 99999;
        border: 1px solid #0f587b;
        background: #fff;
        transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
        -moz-box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
        -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);

        &.open-tool {
            transform: translateX(0);
        }

        .open-close-button {
            background: #404040;
            position: absolute;
            right: -48px;
            width: 48px;
            height: 48px;
            top: -2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            color: #fff;

            &:hover, &:focus {
                color: #fff !important;
            }
        }

        .pojo-a11y-toolbar-title {
            color: #333;
            font-size: 18px;
            padding: 12px 16px;
            margin: 0;
            font-weight: bold;
        }

        .pojo-a11y-tools {
            padding: 0;

            li {
                padding: 6px 16px;

                a {
                    display: flex;
                    outline: none;
                    transition: all .3s ease-in-out;

                    .tool-icon {
                        min-width: 24px;
                        color: #333;
                        font-size: 14px;
                    }

                    .tool-text {
                        color: #333;
                        font-size: 14px;
                        text-decoration: underline;
                        margin: 0;
                    }

                    &:hover {
                        .tool-icon, .tool-text {
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    .accessiblity-classes {
        &.grayscale {
            filter: grayscale(1);
        }

        &.negative-contrast {
            filter: invert(1);
        }

        &.links-underline {
            a, button, .text-underline, span {
                text-decoration: underline !important;
            }
        }

        &.readable-font, &.readable-font * {
            font-family: Arial, Helvetica, sans-serif !important;
        }
    }
.faq-section1 {
    .MuiAccordionSummary-content {
        justify-content: flex-end !important;
        margin: 0 12px;
    }
}

/*.MuiList-root .MuiMenuItem-gutters {
    direction: rtl !important;
}*/