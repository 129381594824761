.dark_image_filter {
    filter: grayscale(100%);
}
header {
    &.header_container {
        background-color: white;
        width: 100%;
        height: 82px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;

        @media screen and (max-width: 600px) {
            background-color: rgba(0,0,0,0.8);
        }
    }

    .header_logo_image_container {
        align-items: center;
        justify-content: center;
        z-index: 1;
        width: 140px;
        margin-left: 30px;

        @media screen and (max-width: 991px) {
            margin-left: 20%;
        }
    }

    .header_logo_image {
        min-width: 140px;
        padding: 10px 0;
    }

    .row {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem;
    }

    .item_trl {
        direction: rtl;
        align-items: center;
        height: 100%;
        justify-content: space-between;
        flex-wrap: nowrap;

        @media screen and (max-width: 991px) {
            max-width: 100%;
        }
    }

    .row::after {
        content: "";
        display: table;
        clear: both;
    }

    .header_menu_items {
        position: relative;

        &.showOnMobile {

            @media screen and (max-width: 991px) {
                position: absolute;
                width: 100%;
                background: #0f587b;
                z-index: 9;
                top: 83px;
                right: 0;
                left: 0;
                flex-direction: column;
            }
        }
    }

    .menu_icon {
        fill: #fff;
        margin-top: 10px;
        margin-left: 20px;
        cursor: pointer;
    }

    .header_logo_menu_items_container {
        display: flex;
        flex-wrap: nowrap;
        width: 70%;
    }

    .header_menu_item {
        direction: rtl;
        text-align: right;
        padding: 10px;
        font-family: Assistant;
        // font-size: 14px;
        font-size: 17px;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.67;
        letter-spacing: normal;
        text-align: left;
        color: #d70007;
        direction: rtl;
        cursor: pointer;
        display: flex;

        @media screen and (max-width: 991px) {
            padding: 15px;
            border-bottom: 1px solid rgb(35 48 62 / 60%);
            color: #fff;
        }
    }


    .header_button_items {
        justify-content: flex-end;
        align-items: center;
        display: inline-flex;
    }

    .header_button_filled {
        //background-color: #d70007;
        background-color: white;
        border-radius: 2rem;
        border: 1px solid black;
        margin: 10px;
        direction: rtl;
        padding: 4px;
        cursor: pointer;
        font-weight: 700;
    }

    .header_button_normal {
        margin: 0;
        direction: rtl;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-weight: 700;
    }

    .header_account_image {
        width: 14px;
        height: 18px;
        margin: 5px;
    }

    .home_page_banner_gb {
        width: 100vw;
        height: auto;
    }

    .darkgray_text {
        color: #000000de;
    }

    .white_text {
        color: white;
    }

    .welcome_text {
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        display: flex;
        align-items: center;
        margin: 0;
        z-index: 999;

        @media screen and (max-width: 600px) {
            color: #fff;
        }

        @media screen and (max-width: 991px) {
            &.logout {
                display: none;
            }
        }
    }
}
