.content-section {
    font-size: 18px;
}

.content-section strong {
    font-size: 24px;
}

.content-section h2 {
    display: inline-block;
    border-bottom: 1px solid #fff;
    padding-bottom: 6px;
    margin-bottom: 40px;
}

.content-section .step {
    line-height: 1.2;
    display: table;
    background-repeat: no-repeat;
    background-size: 125px;
    padding: 25px 20px;
}

.content-section .step:nth-child(odd),
html[dir=rtl] .content-section .step:nth-child(even) {
    background-position: right center;
    padding-right: 125px;
    padding-left: 20px;
}

.content-section .step:nth-child(even),
html[dir=rtl] .content-section .step:nth-child(odd) {
    background-position: left center;
    padding-left: 125px;
    padding-right: 20px;
}

.content-section .number,
.content-section .stepText {
    display: table-cell;
}

.content-section .number {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size: 90px;
    line-height: 68px;
    color: #0991d9;
    padding-right: 15px;
    width: 1%;
}

html[dir=rtl] .content-section .number {
    padding-right: 0;
    padding-left: 15px;
}

.content-section .stepText {
    vertical-align: top;
    width: 99%;
}

@media (min-width: 544px) {
    .content-section {
        font-size: 20px;
    }

    .content-section strong {
        font-size: 30px;
    }

    .content-section .number {
        font-size: 120px;
        line-height: 100px;
    }

    .content-section .step {
        background-size: contain;
        padding: 50px 20px;
    }

    .content-section .step:nth-child(odd),
    html[dir=rtl] .content-section .step:nth-child(even) {
        padding-right: 50%;
        padding-left: 20px;
    }

    .content-section .step:nth-child(even),
    html[dir=rtl] .content-section .step:nth-child(odd) {
        padding-left: 50%;
        padding-right: 20px;
    }
}

@media (min-width: 768px) {
    .content-section .step {
        padding: 100px 20px;
    }
}

.read-more {
    background-color: #194989;
}
