.header {
    background: white;
}
.homePage {
    color: #000;
    font-family: 'Rubik';

    .fs66 {
        font-size: 66px !important;
    }

    .fs44 {
        font-size: 44px !important;
    }

    .fs22 {
        font-size: 22px !important;
    }

    .fs18 {
        font-size: 18px !important;
    }

    .fs16 {
        font-size: 16px !important;
    }

    .gray-bg {
        background-color: #fbfbfb;
    }

    .darkgray-bg {
        background-color: #404040;
    }

    .rtl {
        direction: rtl !important;
    }

    .ltr {
        direction: ltr !important;
    }

    .text-center {
        text-align: center;
    }

    .text-left {
        text-align: left !important;
    }

    .text-right {
        text-align: right !important;
    }

    .fullwidth {
        width: 100%;
    }

    .img-responsive {
        display: block;
        max-width: 100%;
    }

    .section-title-1 {
        width: 100%;
        color: #000;
        font-size: 44px;
        font-weight: 700;
        text-align: center;
        @media screen and (max-width: 600px) {
            font-size: 36px;
            line-height: 1.1;
        }
    }
    .section-title {
        width: 100%;
        color: #000;
        font-size: 44px;
        font-weight: 700;
        text-align: center;
        @media screen and (max-width: 600px) {
            font-size: 36px !important;
            line-height: 1.1;
        }
    }

    .section-descrition {
        width: 100%;
        color: #000;
        font-size: 18px;
        font-weight: normal;
        text-align: center;
    }

    .section-secTitle {
        width: 100%;
        color: #000;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
    }

    .prod-section {
        .prod-wrapper {
            .prod-box {
                align-content: center;
                align-items: center;
                flex-direction: column;
                justify-content: flex-end;
                margin-bottom: 40px;
                width: calc(100% / 6);
                padding: 0 5px;
                @media screen and (max-width: 991px) {
                    width: calc(100% / 3);
                }
                
                @media screen and (max-width: 600px) {
                    width: calc(100% / 2);
                }

                .prod-img {
                    width: auto;
                    max-width: 85px;
                    height: auto;
                    max-height: 85px;
                }

                .prod-title {
                    font-size: 16px;
                    color: #000;
                    font-weight: bold;
                    padding: 10px 0;
                }

                .prod-dropdown {
                    max-width: 114px;
                    width: 100%;
                }
            }
        }
    }

    .btn {
        &.btn-red {
            background: #ff3452;
            border-radius: 20px;
            padding: 15px 60px;
            color: white;
            font-size: 25px;
            line-height: 1;
        }
    }

    .recommended-shops-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .shops-categary {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        overflow-x: auto;

        li {
            width: auto;
            font-size: 22px;
            line-height: 1;
            color: #000000;
            font-weight: bold;
            font-family: "Rubik";
            text-align: center;

            & + li {
                margin-left: 1vw;
            }
        }
    }

    .shop-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        overflow-x: auto;

        li {
            width: auto;

            img {
                min-width: 100px;
                max-width: 100px;
            }

            & + li {
                margin-left: 1vw;
            }
        }
    }

    .footer-section {
        .footer-col {
            flex-direction: column;
        }

        h3 {
            color: #fff;
            font-weight: bold;
        }

        ul {
            padding: 0;
            margin-bottom: 16px;

            li {
                color: #fff;
                font-size: 16px;
                direction: rtl;
                text-align: right;
                padding: 0;

                a, a:hover {
                    color: #fff !important;
                }
            }

            &.social-links {
                display: flex;
                flex-direction: row;

                li {
                    width: auto;
                    margin-top: 5px;

                    & + li {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
