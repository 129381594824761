@mixin font-size($size) {
    font-size: calc(($size * 100) / 1080);
}

@function calc-vw($size) {
    $vh-size: calc($size * 100) / 1920;
    @return #{$vh-size}vw;
}

@function calc-vh($size) {
    $vh-size: calc($size * 100) / 1080;
    @return #{$vh-size}vh;
}

$primaryColore: '#232f3d';

.fivepx_margin {
    margin:5px;
}
.checkboxs {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
        margin-right: 0;
    }
}

.opt-input-fileds {
    align-items: center;
    justify-content: center;
    width: 100%;
    input {
        background-color: transparent;
        border-bottom: 1px solid #232f3d;
        color: #232f3d;
        font-size: calc-vw(60);
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: .5;
        height: calc-vw(72)!important;
        width: calc-vw(32) !important;
        padding: 0;
        margin-right: 0 !important;
        outline: none;
        & + input {
            margin-left: calc-vw(36);
        }
        @media screen and (max-width: 991px) {
            font-size: 42px;
            height: 52px !important;
            width: 32px !important;
            & + input {
                margin-left: 20px;
            }
        }
    }
}
.country-tabs {
    margin-bottom: 15px;
    border: 1px solid #f0f0f2;
    border-radius: 8px;
    .MuiButtonBase-root {
        border: none;
        color: #253746;
        font-size: calc-vh(16);
        &:focus {
            outline: none;
        }
        &.Mui-selected {
            font-weight: bold;
            color: #253746;
        }
        & + .MuiButtonBase-root {
            border-left: 1px solid #f0f0f2;
        }
    }
    .MuiTabs-indicator {
        display: none;
    }
    div{
        overflow:auto !important;
        opacity:1 !important;
    }
    button{
        opacity:1 !important;
    }
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 80%;
    background-color: white;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    border: 1px solid grey;
    border-radius: 5px;
}


.tooltip:hover .tooltiptext {
    visibility: visible;
}
.country-tab-data {
    font-size: calc-vh(16);
    border: 1px solid #f0f0f2;
    border-radius: 8px;

    .data-row + .data-row {
        border-top: 1px solid #f0f0f2;
    }

    .data-col {
        color: #253746;
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        padding: 12px;
        display: flex;
        flex-direction: row;
        width: calc(50% - 28px);

        &:last-child {
            justify-content: flex-end;
            width: 56px;

            button {
                min-width: auto;
            }
        }

        &.dataLabel {
            font-weight: bold;
            font-size: 14px;
        }

        &.dataValue {
            font-size: 14px;
        }
    }
}
.screen-data-title-bottom {
    font-size: calc-vh(16);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: normal;
  text-align: center;
  color: #253746;
  margin-top: calc-vh(40);
}
.mt-24 {
    margin-top: calc-vh(24);
}