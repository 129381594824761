.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255, .9);
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 80px;
    height: 80px;
  }
  &.fixed {
    position: fixed;
    img {
      width: 150px;
      height: 150px;
    }
  }
  &.dark-bg {
    background: rgb(0, 0, 0, .9);
  }
}