.address_card {
  padding: 0;
  border: 1px solid #f0f0f2;
  border-radius: 4px;
  margin: 30px 0 0;
  box-shadow: 0 0 10px 0px #eee;
  overflow: hidden;
  font-size: 16px;
  .default_status  {
    background: #fbfbfb;
    padding: 5px 15px;
    align-items: center;
    font-weight: bold;
    button.add_as_default {
      outline: none;
      min-width: auto;
    border-radius: 50%;
    width: 36px;
    height: 36px;
      svg {
        fill: #9e9e9e;
      }
      &.default_current {
        svg {
          fill: #d7000d;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  .address_row {
    padding: 5px 15px;
    display: flex;
    align-items: center;
    &.label {
      color: #333;
      font-weight: bold;
    }
  }
  .address_actions {
    border-top: 1px solid #f0f0f2;
    margin-top: 5px;
    padding: 5px 15px;
    align-items: center;
    button {
      min-width: auto;
      font-size: 14px;
      color: #666;
      text-transform: capitalize;
      border-radius: 20px;
      text-decoration: underline;
      display: flex;
      & + button {
        margin-right: 8px;
      }
      svg {
        font-size: 15px;
        margin-left: 2px;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        color: #d7000d;
      }
    }
  }
}