.pricing_block_A_3_container {
    width: 100%;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    text-align: center !important;

    p {
        width: 33%;
    }

    
}
.p_100 {
    p {
        width: 100% !important;
        margin: 0.4vw !important;
        margin-left: 0.2vw !important;
        margin-right: 1.2vw !important;
    }
}