button {
    &.btn-action {
        background: #d70007;
        height: 64px;
        padding: 10px 45px;
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        border: none;
        border-radius: 30px;
        outline: none;
        outline-offset: none;
        transition: all .3s ease-in;

        &:hover {
            background: #d70007;
            opacity: 0.95;
            box-shadow: 3px 3px 10px -3px #ccc;
            -moz-box-shadow: 3px 3px 10px -3px #ccc;
            -webkit-box-shadow: 3px 3px 10px -3px #ccc;
        }

        &:focus {
            outline: none;
        }

        @media screen and (max-width: 991px) {
            height: 48px;
            font-size: 14px;
        }
    }

    &.btn-radio {
        height: 51px;
        border: solid 1px #f0f0f2;
        width: 50%;
        border-radius: 0;
        background-color: #f6f6f6;
        font-size: 16px;
        color: #253746;

        &.active {
            background-color: #fff;
            font-weight: bold;
            outline: none;
            color: #d70707;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            background-color: #f6f6f6;
            font-weight: bold;
        }

        @media screen and (max-width: 991px) {
            height: 45px;
        }
    }
}

.screen-footer {
    border-top: 1px solid #f0f0f2;
    padding-top: 2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    color: #d70007;
    font-size: 16px;

    .link {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            opacity: .9;
        }
    }
}

.confirmation-dialog {
    .MuiDialog-paperWidthSm {
        width: 600px;
        max-width: 100%;
        min-height: 240px;
        padding: 20px;
        justify-content: space-between;

        .MuiTypography-h6 {
            font-size: 24px;
            color: #d70007;
            text-align: center;
        }
    }
}
.system-alert {
    .MuiDialog-paperWidthSm {
        width: 500px;
        max-width: 100%;
        min-height: 140px;
        padding: 30px;
        justify-content: space-between;
        .close-icon {
            position: absolute;
            top: 20px;
            left: 20px;
            padding: 0;
            outline: none;
            outline-offset: 0;
            svg {
                fill: #d70007;
                font-size: 36px;
            }
        }
        .alert-content {
            padding: 0;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
            color: #d70007;
            .alert-icon {
                fill: #d70007;
                font-size: 60px;
                margin-bottom: 15px;

            }
            h4 {
                font-size: 36px;
                font-weight: bold;
                margin-bottom: 10px;
                line-height: 1;
            }
            p {
                font-size: 24px;
                font-weight: normal;
                margin-bottom: 10px;

            }
        }
    }

}
.MuiDialog-paperWidthSm {
    .btn-yes {
        background-color: #d70007;
        color: #fff;
        border-radius: 30px;
        padding: 10px 40px;
        font-size: 16px;
        border: 1px solid #d70007;
        outline: none;
        text-transform: capitalize;
        &:hover {
            background-color: #d70007;
            color: #fff;
            opacity: .9;
        }
    }

    .btn-no {
        background-color: #fff;
        color: #d70007;
        border-radius: 30px;
        padding: 10px 40px;
        font-size: 16px;
        border: 1px solid #d70007;
        outline: none;
        text-transform: capitalize;

        &:hover {
            background-color: #fff;
            border: 1px solid #d70007;
            color: #d70007;
            opacity: .9;
        }
    }
}