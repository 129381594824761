@mixin font-size($size) {
    font-size: calc(($size * 100) / 1080);
}

@function calc-vw($size) {
    $vh-size: calc($size * 100) / 1920;
    @return #{$vh-size}vw;
}

@function calc-vh($size) {
    $vh-size: calc($size * 100) / 1080;
    @return #{$vh-size}vh;
}

$redColor: '#d70007';
.padding_17 {
    padding: 13px !important;
}
.reg_popup_data {
    &.left-side {
        position: fixed;
        width: 600px;
        max-width: 100%;
        height: 100%;
        top: 0;
        background: #fff;
        border: 1px solid #f9f9f9;
        right: 600px;
        overflow-y: auto;

        @media screen and (max-width: 1200px) {
            right: 0;
        }
    }

    direction: ltr;

    .screen-progress-steps {
        justify-content: space-around;
        align-items: flex-start;
        padding-bottom: calc-vh(24);
        margin-bottom: calc-vh(12);
        border-bottom: calc-vh(4) solid #f6f6f6;
        direction: rtl;

        .progress-step {
            width: calc(100% / 3);
            text-align: center;

            .step-circle {
                display: inline-flex;
                border: 2px solid #e9e9e9;
                height: calc-vh(48);
                width: calc-vh(48);
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-size: calc-vh(21);
                line-height: calc-vh(21 - 3);
            }

            .step-title {
                font-size: calc-vh(21);
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: #232f3d;
                margin-top: calc-vh(18);
            }

            &.current {

                .step-circle,
                .step-title {
                    font-weight: bold;
                }
            }

            &.done {
                .step-circle {
                    &::before {
                        content: '';
                        content: "";
                        color: #232f3d;
                        position: absolute;
                        margin-left: 1px;
                        margin-top: -1px;
                        width: 14px;
                        height: 8px;
                        border-bottom: solid 2px currentColor;
                        border-left: solid 2px currentColor;
                        transform: rotate(-45deg);
                        background: #fff;
                    }
                }

                .step-title {
                    font-weight: bold;
                }
            }

            &.done_back {
                .step-circle {
                    &::before {
                        position: absolute;
                        margin-left: 1px;
                        margin-top: -1px;
                        width: 14px;
                        height: 8px;
                        border-bottom: solid 2px currentColor;
                        border-left: solid 2px currentColor;
                        transform: rotate(-45deg);
                        background: #dc2228;
                    }
                }

                .red_back {
                    content: '';
                    content: "";
                    color: #232f3d;
                    background: #d70007;
                    color: white;
                    font-weight: bold;
                }

                .step-title {
                    font-weight: bold;
                }
            }
        }

        .progress-step1 {
            width: calc(100% / 4);
            text-align: center;

            .step-circle {
                display: inline-flex;
                border: 2px solid #e9e9e9;
                height: calc-vh(48);
                width: calc-vh(48);
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-size: calc-vh(21);
                line-height: calc-vh(21 - 3);
            }

            .step-title {
                font-size: calc-vh(21);
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.71;
                letter-spacing: normal;
                text-align: center;
                color: #232f3d;
                margin-top: calc-vh(18);
            }

            &.current {

                .step-circle,
                .step-title {
                    font-weight: bold;
                }
            }

            &.done {
                .step-circle {
                    &::before {
                        content: '';
                        content: "";
                        color: #232f3d;
                        position: absolute;
                        margin-left: 1px;
                        margin-top: -1px;
                        width: 14px;
                        height: 8px;
                        border-bottom: solid 2px currentColor;
                        border-left: solid 2px currentColor;
                        transform: rotate(-45deg);
                        background: #fff;
                    }
                }

                .step-title {
                    font-weight: bold;
                }
            }

            &.done_back {
                .step-circle {
                    &::before {
                        color: #232f3d;
                        position: absolute;
                        margin-left: 1px;
                        margin-top: -1px;
                        width: 14px;
                        height: 8px;
                        border-bottom: solid 2px currentColor;
                        border-left: solid 2px currentColor;
                        transform: rotate(-45deg);
                        background: #dc2228;
                    }
                }

                .red_back {
                    background: #d70007;
                    color: white;
                    font-weight: bold;
                }

                .step-title {
                    font-weight: bold;
                }
            }
        }
    }

    .popup_tittle {
        &.lg {
            padding: 0;
            margin-bottom: 5px;
        }

        &.sm {
            padding: 0;
            font-size: 18px;
        }
    }

    .grey-box-area {
        background-color: #f6f6f6;
        border: 1px solid #e9e9e9;
        border-radius: 16px;
        padding: 10px;
        margin-bottom: calc-vh(24);

        .box-row {
            direction: rtl;
            display: flex;
            font-size: calc-vh(24);

            .row-Label {
                font-weight: bold;
                width: 33%;
                padding: 5px 10px;
            }

            .row-Value {
                padding: 5px 10px;
            }
        }
    }

    .dropzone-area {
        border: 2px dashed #e9e9e9;
        border-radius: 16px;

        .upload-area {
            margin: 0;
            width: 100%;
            padding: calc-vh(48) calc-vh(24);
            border-radius: 16px;

            input {
                display: none;
            }

            .upload-content {
                flex-direction: column;
                align-items: center;

                img.upload {
                    width: 20px;
                    height: 20px;
                    margin-bottom: 10px;
                }

                .content-line-1 {
                    font-size: 16px;
                    line-height: 1.5;
                    font-weight: 300;
                    color: #232f3d;
                    text-align: center;
                }

                .content-line-2 {
                    font-size: 12px;
                    line-height: 2;
                    color: #232f3d;
                }
            }
        }
    }

    .form-action {
        &.grid-action {
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;

            .left-action-btn {
                height: 44px;
                font-size: 16px;
                white-space: nowrap;
                margin-right: 10px;
            }

            .right-action-text {
                font-size: 16px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #232f3d;
                text-align: right;

                .link {
                    cursor: pointer;
                    text-decoration: underline;
                    white-space: nowrap;
                }
            }
        }
    }

    .screen-bottom-text {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.71;
        text-align: center;
        color: #232f3d;
        margin-top: calc-vh(12);
    }

    .label-wrapper {
        display: flex;
        justify-content: space-between;
        direction: rtl;
        padding: 0;
    }

    .input-wrapper {
        display: flex;
        direction: rtl;
    }

    .input-label {
        font-weight: bold;
    }

    .personal_popup_tittle {
        font-size: 14px;
        color: black;
        display: flex;
        align-items: center;
        text-align: center;
        line-height: 1.11;
        margin-bottom: 16px;
        font-family: Assistant !important;
    }

    .check-box {
        direction: rtl;
        margin-right: calc-vw(-15);

        span {
            font-size: 16px;
            line-height: 1.2;
        }
    }

    .account_type_btn {
        font-weight: bold;
        text-align: left;
        line-height: 2.52;
        color: red;
        display: flex;
        text-align: right;
        align-items: center;
        justify-content: flex-end;
        padding-right: calc-vw(15);
        color: #dc2228;
    }

    .Business {
        display: flex;
        text-align: end;
        align-items: center;
        font-weight: 300;
        line-height: 1.88;
        height: calc-vh(51);
        padding: calc-vw(20) calc-vh(62) calc-vw(19);
        object-fit: contain;
        border: solid 1px #f0f0f2;
        background-color: #f6f6f6;
        color: #1f5e7f;
        cursor: pointer;
    }

    .private {
        display: flex;
        text-align: end;
        align-items: center;
        font-weight: 300;
        line-height: 1.88;
        height: calc-vh(51);
        padding: calc-vw(20) calc-vh(62) calc-vw(19);
        object-fit: contain;
        color: #1f5e7f;
        border: solid 1px #f0f0f2;
        background-color: #f6f6f6;
        cursor: pointer;
    }

    .add_data {
        padding: 10px;
        text-align: left;
        color: black;
    }

    .selected_acc {
        background-color: white;
        font-weight: bold;
        color: #d70007;
    }
    // .screen_otp_title_2 {
    //     font-size: calc-vw(24) !important;
    //     font-weight: 300;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 1.67;
    //     letter-spacing: normal;
    //     text-align: center;
    //     color: #253746;
    // }
    // .screen_otp_footer_2 {
    //     font-size: calc-vw(16.5) !important;
    //     font-weight: normal;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 2.52;
    //     letter-spacing: normal;
    //     text-align: center;
    //     color: #d70007;
    //     text-decoration: underline;
    // }
    // .screen-footer-btn {
    //     font-size: calc-vw(22);
    //     font-weight: bold;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 0.82;
    //     letter-spacing: normal;
    //     height: 44px;
    // }
    // .screen-footer {
    //     font-size: calc-vw(16.5);
    //     font-weight: normal;
    //     font-stretch: normal;
    //     font-style: normal;
    //     line-height: 2.52;
    //     letter-spacing: normal;
    //     text-align: center;
    // }
    // .text-field {
    //     width: 100%;
    //     direction: rtl;
    //     min-height: calc-vh(51);
    //     font-size: 1.48vh;
    //     label.Mui-error {
    //         color: #253746;
    //     }
    //     input[type=text] {
    //         background-color: #fff;
    //         line-height: 1.88;
    //         font-size: calc-vw(16);
    //     }
    //     input[type=number] {
    //         background-color: #fff;
    //         line-height: 1.88;
    //         font-size: calc-vw(16);
    //     }
    //     fieldset.MuiOutlinedInput-notchedOutline {
    //         border: 1px solid #f0f0f2;
    //     }
    // }

    .text-field-otp {
        width: 3vw;
        direction: rtl;
        height: calc-vh(51);
        font-size: 2vw;

        label.Mui-error {
            color: #253746;
        }

        input[type=text] {
            background-color: #fff;
            line-height: 0.67;
            font-weight: bold;
            font-size: 3.125vw;
            margin-right: 0;
            margin-left: 0;
        }

        input[type=number] {
            background-color: #fff;
            line-height: 0.67;
            font-weight: bold;
            font-size: 3.125vw;
            margin-right: 0;
            margin-left: 0;
        }

        fieldset.MuiOutlinedInput-notchedOutline {
            border: none;
            border-bottom: 1px solid black;
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.divider {
    flex-grow: 1;
    border-color: darkgray;
    margin-top: 23px;
}