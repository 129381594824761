dialog {
    display: none;
    position: absolute;
    inset-inline-start: 0px;
    inset-inline-end: 0px;
    width: fit-content;
    height: fit-content;
    background-color: canvas;
    color: canvastext;
    margin: auto;
    border-width: initial;
    border-style: solid;
    border-color: initial;
    border-image: initial;
    padding: 1em;
}

dialog[open] {
    display: block;
}

.shipping_transaction_table {
    td {
        max-width: 10vw;
    }
}
