@import url('../../css/_mixins.scss');

.title-section {
    min-height: 200px;

    @media screen and (max-width: 991px) {
        min-height: 120px;
        padding: 20px 0;
    }

}

h1.page-main-title {
    font-size: 60px;
    color: #404040;
    font-weight: bold;
    text-align: center;
    line-height: 1;

    @media screen and (max-width: 991px) {
        font-size: 24px;
    }
}
.store_tips {
    color: #333;
    direction: rtl;
    font-size: 15px;
    padding-right: 15px;
}

p.page_sub_title {
    font-size: 24px;
    color: #404040;
    text-align: center;

    @media screen and (max-width: 991px) {
        font-size: 18px;
    }
}
.mobile_center {
    @media screen and (max-width: 991px) {
        justify-content: center !important;
    }
}
.img_bg1 {
    background: #fff;
    border-radius: 5px;
    display: block;
    margin-top: 10px;
    padding: 8px;

    .flag_img_sqr {
        height: 100%;
        margin: 0 auto;
        display: block;
    }
}

.store_select {
    padding: 2px !important;
    border-radius: 50px !important;
    min-width: 13vw !important;
}

.popular-section {
    padding: 90px 0;

    @media screen and (max-width: 991px) {
        padding: 30px 0;
    }
}

h1.section-title {
    font-size: 60px;
    color: #d70007;
    text-align: center;
    margin-bottom: 30px;
    font-weight: bold;

    @media screen and (max-width: 991px) {
        font-size: 30px;
    }
}

.custom_container {
    max-width: 90%;
    margin: 0 auto;
}

.popular-section {
    .shadow_box {
        box-shadow: 0px 0px 25px -5px #ddd;
        -moz-box-shadow: 0px 0px 25px -5px #ddd;
        -webkit-box-shadow: 0px 0px 25px -5px #ddd;
        border-radius: 10px;
        padding: 15px;
        margin-bottom: 20px;


        .head {
            direction: rtl;

            div + div {
                margin-right: 36px;
            }

            .marker-icon {
                width: 12px;
                margin-left: 10px;
            }
        }

        .content {
            margin: 15px auto;
            height: 50px;
            height: 50px;
            align-items: center;
            justify-content: center;

            img {
                height: auto;
                margin: 0 auto;
                // max-width: 90%;

                &.small {
                    height: 35px;
                    margin-bottom: 10px;
                }

                @media screen and (max-width: 991px) {
                    height: auto;
                    max-width: 100%;
                    margin: 20px auto;

                    &.small {
                        height: auto;
                        max-width: 100%;
                        margin: 20px auto;
                    }
                }
            }

            p {
                color: #232f3d;
                font-size: 16px;
                text-align: center;
                font-weight: bold;
                line-height: 1.2;
            }
        }

        .action {
            margin: 5px 0;

            .action-btn {
                border-radius: 20px;
                text-align: center;
                margin: 0 auto;
                color: #d70007;
                width: 140px;
                font-weight: bold;
                height: 36px;

                @media screen and (max-width: 991px) {
                    width: 100%;
                }

                &.underline-btn {
                    border: 1px solid #d70007;
                }

                &.filled-btn {
                    border: 1px solid #d70007;
                    background-color: #d70007;
                    color: #fff;
                }
            }
        }
    }

    .brand_card_title {
        font-size: 18px;
        font-weight: bold;
        padding: 6px;
        width: 100%;
        text-align: center;
        // background: #0f587b;
        background: #fbfbfb;
        color: #404040;
        border-radius: 5px;
        margin-bottom: 20px;
    }
}

button.action-filled-btn {
    border-radius: 40px;
    text-align: center;
    margin: 0 auto;
    width: 334px;
    max-width: 100%;
    font-weight: bold;
    background-color: #d70007;
    color: #fff;
    height: 50px;
    margin: 30px auto 0;
    font-size: 16px;

    &:hover {
        opacity: .9;
        background-color: #d70007;
        color: #fff;
    }
}

.fillter-tabs {
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    .tabs-list {
        list-style: none;
        display: flex;
        direction: rtl;
        padding: 10px 0;
        border-bottom: 1px solid rgb(35 47 61 / 12%);
        flex-direction: row;
        overflow-x: auto;
        overflow-y: hidden;
        @media screen and (max-width: 767px) {
            &.sub_tabs_list {
                flex-direction: column;
                li {
                    text-align: center;
                    margin: 0;
                }
            }
        }

        li {
            &.whitespace_nowrap {
                white-space: nowrap;
            }
            &+li {
                margin-right: 20px;
            }

            a {
                color: #232f3d;
                font-size: 16px;
                padding: 5px 0 10px;
                white-space: nowrap;
            }

            &.active {
                a {
                    font-weight: bold;
                    border-bottom: 3px solid #232f3d;
                }
            }
        }
    }
}
.word_break {
    word-break: break-all;
}