ul.retailers {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 40px;
    padding: 0;
}

.retailers li {
    list-style-type: none;
    flex: 0 0 95px;
    margin: 10px;
    background-color: #c9d4d6;
    text-transform: uppercase;
    font-size: 9px;
    text-align: center;
}

@media (min-width: 544px) {
    .retailers li {
        flex-basis: 150px;
        margin: 25px;
    }
}

.retailers li a {
    color: #3b5154;
}

.retailers .retailer-name {
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
}

.retailers img {
    width: 100%;
}

.retailers .retailer-text {
    padding: 6px 0;
}

@media (min-width: 544px) {
    .retailers .retailer-text {
        padding: 10px;
    }
}
