@mixin font-size($size) {
    font-size: calc(($size * 100) / 1080);
}

@function calc-vh($size) {
    $vh-size: ($size * 100) / 1080;
    @return #{$vh-size}vh;
}

.sidebar-dialog {
    position: fixed;
    z-index: 999;
    width: 100vw;
    height: 100vh;
    background: #00000054 !important;
    top: 0;
    right: 0;
    direction: rtl;

    .sidebar-form-dialog {
        width: 600px;
        min-width: 480px;
        max-width: 600px;
        background: #fff;
        margin-left: auto;
        padding: 3rem;
        position: relative;
        overflow-y: auto;
        min-height: 100vh;
        max-height: 100%;
        overflow-x: hidden;

        .close-dialog-btn {
            position: absolute;
            top: 10px;
            left: 10px;
            min-width: auto;
            width: 30px;
            height: 30px;

            &:focus {
                outline: none;
            }
        }

        .back-dialog-btn {
            top: 3px;
            position: absolute;
            right: 0;
            color: #d70007;
            font-weight: bold;

            &:focus {
                outline: none;
            }
        }

        .close-dialog {
            color: #d70007;
            font-size: calc-vh(45);
        }
    }
}

@media only screen and (max-width: 991px) {
    .sidebar-dialog {
        .sidebar-form-dialog {
            padding: 30px 20px;
            width: 100vw;
            min-width: 100vw;
        }
    }
}